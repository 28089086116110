






















































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { downloadJSON } from '@/utils/fileUtils.ts'
import { vxm } from '@/store'

@Component({})
export default class StockValueItemsReport extends Vue {
  private loading = false

  public $refs: Vue['$refs'] & {
    listIdFilterForm: {
      validate: any
      reset: any
    }
  }

  private filters = {
    listId: null as string | null,
  }

  private exportJsonLoading = false
  private stockValueItemsData = null

  // Table headers defined in script
  private tableHeaders = [
    { text: this.$t('c:stock-details:Product ID'), value: 'productId' },
    { text: this.$t('c:stock-details:Product name'), value: 'productName' },
    { text: this.$t('c:stock-details:Position'), value: 'position' },
    { text: this.$t('c:stock-details:Quantity'), value: 'quantity' },
    { text: this.$t('c:stock-details:Unit cost'), value: 'unitCost' },
    { text: this.$t('c:stock-details:Unit fee'), value: 'unitFee' },
    { text: this.$t('c:stock-details:Stock value'), value: 'stockValue' },
    { text: this.$t('c:stock-details:Product brand'), value: 'productBrand' },
    { text: this.$t('c:stock-details:Product type'), value: 'productType' },
    { text: this.$t('c:stock-details:Product class'), value: 'productClass' },
    { text: this.$t('c:stock-details:Product width'), value: 'productWidth' },
    { text: this.$t('c:stock-details:Product height'), value: 'productHeight' },
    { text: this.$t('c:stock-details:Product diameter'), value: 'productDiameter' },
  ]

  private executeReport() {
    if (this.$refs.listIdFilterForm.validate()) {
      this.loading = true
      this.$axios
        .get('/v4/site/stock/value-list-items', { params: this.filters })
        .then((response) => {
          if (Array.isArray(response.data.data.products) && response.data.data.products.length > 0) {
            this.stockValueItemsData = response.data.data
          } else {
            vxm.alert.warning(this.$t('c:stock-details:No data found with this List ID'))
          }
        })
        .catch((error) => {
          console.error('Error fetching report:', error)
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      vxm.alert.error(this.$t('c:stock-details:Please fill the required filters'))
    }
  }

  private clear() {
    this.filters = {
      listId: null,
    }
    this.stockValueItemsData = null
  }

  private handleJsonDownload() {
    downloadJSON(this.stockValueItemsData, 'stock_value_items', this.setExportJsonLoading)
  }

  private setExportJsonLoading(loading: boolean): void {
    this.exportJsonLoading = loading
  }
}
